.Query > .MuiCircularProgress-root {
  display: block;
  margin-top: 2em;
  margin-left: auto;
  margin-right: auto;
}

.Query > .error {
  text-align: center;
}

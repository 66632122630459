@media print {
  body {
    display: block;
  }
}
.label_print_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  min-height: 920px;
}
span {
  font-size: 12px;
}
.label {
  max-width: 350px;
  max-height: 460px;
  width: 350px;
  height: 460px;
  display: block;
  flex-direction: column;
  overflow: hidden;
  border: 0.5pt solid black;
}
.logo {
  width: 100%;
  height: 30px;
  margin-bottom: 5px;
  align-self: center;
  margin-top: 10px;
}
.section {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 7px;
  padding: 0 10px;
}
.requirement_section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  padding: 0 10px;
}
.column {
  width: 50%;
  flex-direction: column;
  display: flex;
  margin: 5px;
}
.title {
  font-weight: 900;
  font-size: 15px;
  text-transform: uppercase;
  margin: 2px;
  margin-left: 0;
}
.bold {
  font-weight: 900;
  margin: 0;
}
.driver_name {
  border: 1px black solid;
  background-color: burlywood;
  align-self: flex-end;
  padding: 5px;
  margin-top: 5px;
}
.unique_number {
  align-self: flex-end;
  border: 1px solid black;
  padding: 5px 10px;
  margin-top: 5px;
}
.qr_code {
  margin-top: 5px;
  width: 110px;
  height: 120px;
}
.border_right {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0.1pt solid black;
}
.divider {
  width: 100%;
  height: 0.01em;
  background-color: rgba(0, 0, 0, 0.25);
}

@media all {
  .pageBreak {
    display: none;
  }
}

.bold {
  font-weight: bold !important;
}

@media print {
  html,
  body {
    height: initial;
    overflow: initial;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .pageBreak {
    display: block;
    page-break-before: auto;
    page-break-after: auto;
  }
}

.tooltip_wrapper {
  display: inline-block;
  position: relative;
}
.tooltip_text {
  font-size: 10px;
  visibility: hidden;
  padding: 0.3rem;
  background-color: #757575;
  color: #fff;
  text-align: center;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: max-content;
  bottom: 0;
  transform: translateX(-50%) translateY(100%);
  left: 50%;
}
.tooltip_text::after {
  content: ' ';
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #757575 transparent;
}
.tooltip_wrapper:hover .tooltip_text {
  visibility: visible;
}
.hide {
  display: none;
}

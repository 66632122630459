.numbers {
  padding: 10px;
  margin: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.viewNumbers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 100px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.graphIcon {
  margin: 0 10px;
}
.boxTitle {
  font-size: 15px;
  font-weight: bold;
  color: '#0E6F37';
}
.numberBoxes {
  display: grid;
  grid-template-rows: 1fr;
}
.numberBoxes1 {
  grid-template-columns: 1fr;
}
.numberBoxes2 {
  grid-template-columns: 1fr 1fr;
}
.numberBoxes3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.numberBoxes4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.numberBoxes5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.numberBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  background-color: #edf0f6;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.viewNumbers:hover,
.numberBox:hover {
  cursor: pointer;
  opacity: 0.5;
}
.numberTitle {
  font-weight: bold;
  font-size: 17px;
  color: #0e6f37;
  margin-bottom: 5px;
}
.numberValue {
  font-size: 25px;
  color: #3f9a32;
  font-weight: bolder;
  margin-bottom: 5px;
}
